import { GetServerSidePropsContext, NextPage } from "next";
import {
  Anchor,
  Box,
  Button,
  Center,
  Divider,
  Group,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useRouter } from "next/router";
import { withIronSessionSsr } from "iron-session/next";
import { ironOptions } from "utils/config";
import { TextInput, PasswordInput } from "ui/components/Input";
import { Logo } from "ui/components/Logo";
import Link from "next/link";
import { usePost } from "hooks/useRequest";
import { User } from "@~internal/prisma";
import { useEffect } from "react";
import { prisma } from "utils/prisma";
import { GoogleLogin } from "@react-oauth/google";
import { useMediaQuery } from "@mantine/hooks";
import { FEATURE_FLAGS } from "utils/feature-flags";
import { useSaveWorkspaceFromInvite } from "hooks/useSaveWorkspaceFromInviteId";

async function getServerSidePropsFunction({
  req,
  query,
}: GetServerSidePropsContext) {
  const { inviteid, email } = query as { inviteid: string; email: string };
  // if the user is already logged in, redirect to the home page
  if (req.session?.user?.id) {
    // accept invite if there's one
    if (inviteid && email && req.session.user.email === email) {
      const invite = await prisma.invite.findFirst({
        where: { id: inviteid, email },
      });

      if (invite) {
        const role = await prisma.workspaceRole.create({
          data: {
            role: invite.role,
            workspace: {
              connect: { id: invite.workspaceId },
            },
            user: {
              connect: { id: req.session.user.id },
            },
          },
        });

        if (!!role) {
          await prisma.invite.delete({ where: { id: inviteid } });
        }
      }

      return {
        redirect: {
          destination: "/",
          permanent: false,
        },
      };
    } else if (!inviteid && !email) {
      return {
        redirect: {
          destination: "/",
          permanent: false,
        },
      };
    }
  }

  return {
    props: {
      inviteid: inviteid || null,
      email: email || null,
    },
  };
}

export const getServerSideProps = withIronSessionSsr(
  getServerSidePropsFunction,
  ironOptions
);

interface FormValues {
  email: string;
  password: string;
  inviteid?: string;
}

type Props = {
  inviteid?: string;
  email?: string;
};

const Signin: NextPage<Props> = ({ inviteid, email }) => {
  const router = useRouter();
  const { post, data, isLoading } = usePost<Partial<User>>();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);
  useSaveWorkspaceFromInvite(inviteid);

  const form = useForm<FormValues>({
    initialValues: {
      email: email || "",
      password: "",
      inviteid,
    },
  });

  const handleSubmit = async (values: FormValues) => {
    await post("/api/auth/signin", values);
  };

  useEffect(() => {
    if (data?.id) {
      router.push("/");
    }
  }, [data, router]);

  const signUpLink = `/signup${
    inviteid ? `?inviteid=${inviteid}&email=${encodeURIComponent(email!)}` : ""
  }`;

  return (
    <Center pt={isMobile ? 100 : 200} pb={40}>
      <Box
        sx={(theme) => ({
          width: "400px",
          [theme.fn.smallerThan("xs")]: {
            maxWidth: "300px",
          },
        })}
      >
        <Center mb="xl">
          <Stack spacing="xl">
            <Logo />
            {FEATURE_FLAGS.GOOGLE_SIGNIN && (
              <Title order={3}>Welcome back, sign in with</Title>
            )}
          </Stack>
        </Center>
        {FEATURE_FLAGS.GOOGLE_SIGNIN && (
          <>
            <GoogleLogin
              text="signin_with"
              width={isMobile ? "300px" : "400px"}
              locale="en_US"
              onSuccess={({ credential }) => {
                post("/api/auth/signin", { token: credential });
              }}
              onError={() => {
                console.log("Login Failed");
              }}
            />
            <Divider
              label="Or continue with email"
              labelPosition="center"
              my="lg"
            />
          </>
        )}

        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            required
            label="Email"
            type="email"
            mb="sm"
            disabled={!!inviteid}
            {...form.getInputProps("email")}
          />
          <PasswordInput
            required
            label="Password"
            description={
              <Text size="xs">
                Forgot yours? It happens,{" "}
                <Link href="/forgot-password" passHref>
                  <Anchor size="xs">no worries</Anchor>
                </Link>
                !
              </Text>
            }
            {...form.getInputProps("password")}
          />
          <Group
            position="apart"
            mt="xl"
            sx={(theme) => ({
              [theme.fn.smallerThan("xs")]: {
                flexDirection: "column-reverse",
              },
            })}
          >
            <Link href={signUpLink} passHref>
              <Anchor size="sm">Don&apos;t have an account yet?</Anchor>
            </Link>
            <Button
              type="submit"
              variant="light"
              loading={isLoading}
              sx={(theme) => ({
                [theme.fn.smallerThan("xs")]: { width: "100%" },
              })}
            >
              Sign In
            </Button>
          </Group>
        </form>
      </Box>
    </Center>
  );
};

export default Signin;
